import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'uuid'
export const UUID = ({uuid,data,doDelete}) =>{
  const req = Request();
  if(!data){
    if(!doDelete){
      return axiosInstance.post(`/${moduleName}/fetch`,{uuid},req).then(ResponseData).catch(ResponseError)
    }else{
      return axiosInstance.post(`/${moduleName}/delete`,{uuid},req).then(ResponseData).catch(ResponseError)
    }
  }else{
    return axiosInstance.post(`/${moduleName}/save`,{uuid,data},req).then(ResponseData).catch(ResponseError)
  }
}

export default {
  UUID,
}