<template>
  <div>
  <v-form v-model="form">
    <v-card>
      <v-card-title>
        UUID VIEWER
      </v-card-title>
      <v-card-text>
          <v-row>
            <v-col
              cols=3
            >
              <v-text-field
                v-model="uuid"
                label="UUID"
                :rules="[required('UUID')]"
              />
            </v-col>
            <v-col
              cols=9
            >
              <v-textarea
                v-model="content"
                label="Content"
              />
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          :disabled="!form"
          @click="fetchUUID"
        >
          Lấy dữ liệu
        </v-btn>
        <v-spacer />
        <v-btn
          color="red"
          :disabled="content === undefined"
          @click="delUUID"
        >
          Xóa
        </v-btn>
        <v-btn
          color="green"
          :disabled="content === undefined"
          @click="saveUUID"
        >
          Lưu
        </v-btn>
      </v-card-actions>
      <loading :status="loader" />
    </v-card>
  </v-form>
  </div>
</template>

<script>
import validations from '@/helpers/validations.js';
import {UUID} from '@/modules/uuid';
import { ThongBao } from '@/helpers'
import loading from '@/components/base/loading.vue';
export default {
  components:{
    loading,
  },
  data () {
    return {
      form:false,
      uuid:undefined,
      content: undefined,
      loader:false,
    }
  },
  watch:{
    uuid(v){
      if(!!v) this.uuid = v.trim();
    }
  },
  methods:{
    ...validations,
    fetchUUID(){
      const uuid = this.uuid;
      this.content = undefined;
      this.loader = true;
      UUID({uuid}).then(resp=>{
        const {data,error} = resp;
        if(data){
          this.content= data;
        }else{
          const err = error || "Dữ liệu không hợp lệ !!!";
          ThongBao.Error(err);
        }
      }).catch(err=>{
        ThongBao.Error(err);
      }).finally(()=>{
        this.loader = false;
      });
    },
    delUUID(){
      const uuid = this.uuid;
      this.loader = true;
      UUID({uuid,doDelete:true}).then(resp=>{
        const {success,error} = resp;
        if(success){
          this.content = undefined;
          ThongBao.Success('Đã xóa dữ liệu thành công !!!');
        }else{
          const err = error || "Dữ liệu không hợp lệ !!!";
          ThongBao.Error(err);
        }
      }).catch(err=>{
        ThongBao.Error(err);
      }).finally(()=>{
        this.loader = false;
      });
    },
    saveUUID(){
      const uuid = this.uuid;
      const data = this.content;
      this.loader = true;
      UUID({uuid,data}).then(resp=>{
        const {success,error} = resp;
        if(success){
          ThongBao.Success('Đã lưu dữ liệu thành công !!!');
        }else{
          const err = error || "Dữ liệu không hợp lệ !!!";
          ThongBao.Error(err);
        }
      }).catch(err=>{
        ThongBao.Error(err);
      }).finally(()=>{
        this.loader = false;
      });
    },
  },
  mounted(){ 
    const {uuid} = this.$route.query;
    if(uuid){
      this.uuid = uuid;
    }
  }
}
</script>

<style>

</style>